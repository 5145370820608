import moment from "moment";

export const getCountMinutes = (startTime, endTime) => {
  let startTimeMoment = moment(startTime, "HH:mm");
  let endTimeMoment = moment(endTime, "HH:mm");

  let duration = moment.duration(endTimeMoment.diff(startTimeMoment));
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) - hours * 60;

  return hours * 60 + minutes;

}

export const timeToMinutes = (time) => {
    let [hours, minutes] = time.split(":");

    hours = Number(hours);
    minutes = Number(minutes);

    let totalMinutes = hours * 60;
    totalMinutes += minutes;

    return totalMinutes
}

export const convertMinutesToTimes = (minutes) => {

  const hours = parseInt(Math.floor(minutes / 60));
  const minutesC = minutes % 60;

  return moment(`${hours}:${minutesC}`, "H:m").format('HH:mm');

}

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const minutesC = minutes % 60;

  return `${padWithLeadingZeros(hours, 2)}:${padWithLeadingZeros(minutesC, 2)}`;
}

/**
 *
 * @param {Number} hours
 * @param {Number} minutes
 * @param {Number} seconds
 */
export const convertTimeToSecond = (hours, minutes, seconds) => {
  return (hours * 3600) + (minutes * 60) + seconds
}

/**
 *
 * @param {Number} num
 * @returns string
 */
export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

/**
 *
 * @param {Number} totalSeconds
 * @returns string
 */
export function convertScToTime(totalSeconds) {
  return new Date(totalSeconds * 1000).toISOString().slice(11, 19)
}

/**
 *
 * @param {Number} totalSeconds
 * @returns string
 */
 export function convertScToTimeFormat(totalSeconds) {
  const time = new Date(totalSeconds * 1000).toISOString().slice(11, 19);
  const arr = time.split(':');
  return arr.map((a, idx) => {
    if(idx === 0) return a.concat('時')
    else if(idx === 1) return a.concat('分')
    else return a.concat('秒')
  }).join('');
}
