export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

/**
 * Get auto refresh setting
 * @returns string
 */
export const getAutoRefreshSetting = () => {
  let secondStorage = localStorage.getItem("second");
  if (!secondStorage || secondStorage === 'null') {
    localStorage.setItem("second", "30");
    secondStorage = "30";
  }

  return secondStorage;
};
