<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">

      <div class="col-xxl-9 col-md-9" v-if="isShow">
        <div class="row">

          <div class="col-xxl-12 col-md-12" v-if="!loading && facility">
            <FacilityInformation :isMyFacility="false" :facilityId="facilityIDChange" @reload="loadFacility" :facility="facility" />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_DRIED" :isMyFacility="false" :facilityId="facilityIDChange" />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_WET" :isMyFacility="false" :facilityId="facilityIDChange" />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_RESTED" :isMyFacility="false" :facilityId="facilityIDChange" />
          </div>

        </div>
      </div>

      <div class="col-xxl-3 col-md-3" v-if="isShow">
        <div class="row">
          <div class="col-xxl-12 col-md-12" v-if="!loading && facility">
            <FacilityFilterInformation :isMyFacility="false" :facilityId="facilityIDChange" @reload="loadFacility" :facility="facility" />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Settings />
          </div>
        </div>
      </div>

    </div>

    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import FacilityInformation from "./components/FacilityInformation";
import FacilityFilterInformation from "./components/FacilityFilterInformation";
import Settings from "./components/Settings";
import Room from "./components/Room";
import { facilityService } from "@/services/facility.service";
import {mapGetters} from "vuex";

export default {
  page: {
    title: '施設情報管理',
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FacilityInformation,
    FacilityFilterInformation,
    Settings,
    Room,
  },
  data() {
    return {
      title: this.$i18n.t('t-facility-information-management'),
      items: [
        {
          text: this.$i18n.t('t-setting_'),
          active: true
        },
        {
          text: this.$i18n.t('t-facility-information-management').toUpperCase(),
          active: true,
        },
      ],
      loading: false,
      facility: null,
      facilityServices: null,
      loadingFacilityInformation: false,
      isShow: false
    };
  },
  computed: {
    ...mapGetters({
      facilityIDChange:'settings/getFacilityId'
    }),
  },
  watch: {
    facilityIDChange(newData) {
      this.loadFacility(newData);
    }
  },
  mounted() {
    this.loadFacility('');
  },
  methods: {
    async loadFacility(facilityId = '') {
      try {
        const paramFacilityId = facilityId.length === 0 ? localStorage.getItem('currentFacilityId') : facilityId;
        this.isShow = paramFacilityId !== null;
        if (paramFacilityId) {
          this.loading = true;
          this.facility = await facilityService.getFacility(paramFacilityId);
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    }
  },
};
</script>
