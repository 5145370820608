<template>
  <div class="card card-body">
    <div class="row table-component">
      <div class="col-xxl-12 col-md-12">
        <div class="align-middle flex justify-between line-header">
          <div class="fs-16 table-header-title flex align-center justify-center">{{ facilityTitle }}</div>
          <div v-if="!isCreate">
            <b-button variant="primary" :disabled="state.loadingAction || disabled" @click="onSubmit"
                      class="waves-effect waves-light">
              <div class="flex">
                <span v-if="state.loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
                {{ $t("t-renew") }}
              </div>
            </b-button>
          </div>
        </div>
      </div>

      <div v-if="!state.loading" class="col-xxl-12 col-md-12">
        <div class="row py-3">
          <div class="col-xxl-4 col-md-4">

            <div>
              <span>{{ $t("t-facility-ID") }}</span>
              <div class="font-weight-bold mt-2">{{ isCreate ? facilityIdStr : facilityForm.facilityIdStr }}</div>
            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-facility-name") }} <span
                class="text-danger">*</span></label>
              <input
                type="text" v-model="v$.name.$model"
                :disabled="disabled"
                :class="{ 'is-invalid': (v$.name.$error && invalid) || invalidFacilityName, 'highlight': (compareObject && state.resultCompare.name) }"
                @input="invalidFacilityName = false"
                class="form-control"
                :maxlength="255"
              >

              <div v-if="(v$.name.$error && invalid) || invalidFacilityName" class="invalid-feedback">

                <div v-if="v$.name.required.$invalid">
                  {{ $t('field_required', {field: $t('facility.name')}) }}
                </div>

                <div v-else-if="invalidFacilityName">
                  {{ $t('duplicated', {field: $t('facility.name')}) }}
                </div>

              </div>

            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-address") }}<span class="text-danger">*</span></label>
              <div class="flex align-items-center"
                   :class="{ 'is-invalid': (v$p.first.$invalid || v$p.last.$invalid) && invalid }">
                <div class="mr-2">〒</div>

                <input v-model="v$p.first.$model" :disabled="disabled" v-maska="['', '###']" @change="getPostalCode"
                       type="text" class="form-control"
                       :class="[{'highlight': (compareObject && state.resultCompare.start)}]">

                <div class="mx-2">ー</div>
                <input v-model="v$p.last.$model" :disabled="disabled" v-maska="['', '####']" @change="getPostalCode"
                       type="text" class="form-control"
                       :class="[{'highlight': (compareObject && state.resultCompare.end)}]">

              </div>

              <div v-if="(v$p.first.$invalid || v$p.last.$invalid) && invalid" class="invalid-feedback">

                <div v-if="v$p.first.required.$invalid || v$p.last.required.$invalid">
                  {{ $t('field_required', {field: $t('postal-code')}) }}
                </div>

                <div v-else-if="v$p.first.haftwidth.$invalid || v$p.last.haftwidth.$invalid">
                  {{ $t('field_haftwidth', {field: $t('postal-code')}) }}
                </div>

                <div
                  v-else-if="
                    v$p.first.minLength.$invalid
                  || v$p.last.minLength.$invalid
                  || v$p.first.maxLength.$invalid
                  || v$p.last.maxLength.$invalid
                  ">
                  {{ $t('invalid_postal_code') }}
                </div>

              </div>

              <div class="mt-3">
                <label for="name" class="form-label">{{ $t("t-address4") }}<span class="text-danger">*</span></label>
                <input type="text" :maxlength="255" v-model="v$.address4.$model"
                       :class="{ 'is-invalid': invalid && v$.address4.$invalid ,'highlight': (compareObject && state.resultCompare.address4)}"
                       :disabled="disabled" class="form-control">

                <div v-if="v$.address4.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.address4.required.$invalid">
                    {{ $t('field_required', {field: $t('t-address4')}) }}
                  </div>

                  <div v-if="v$.address4.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 255, field: $t('t-address4')}) }}
                  </div>

                </div>
              </div>

              <div class="mt-3">
                <label for="name" class="form-label">{{ $t("t-address1") }}<span class="text-danger">*</span></label>
                <input type="text" :maxlength="255" v-model="v$.address1.$model"
                       :class="{ 'is-invalid': invalid && v$.address1.$invalid ,'highlight': (compareObject && state.resultCompare.address1)}"
                       :disabled="disabled" class="form-control">

                <div v-if="v$.address1.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.address1.required.$invalid">
                    {{ $t('field_required', {field: $t('t-address1')}) }}
                  </div>

                  <div v-if="v$.address1.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 255, field: $t('t-address1')}) }}
                  </div>

                </div>
              </div>

              <div class="mt-3">
                <label for="name" class="form-label">{{ $t("t-address2") }}<span class="text-danger">*</span></label>
                <input type="text" :maxlength="50" v-model="v$.address2.$model"
                       :class="{ 'is-invalid': invalid && v$.address2.$invalid ,'highlight': (compareObject && state.resultCompare.address2) }"
                       :disabled="disabled" class="form-control">

                <div v-if="v$.address2.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.address2.required.$invalid">
                    {{ $t('field_required', {field: $t('t-address2')}) }}
                  </div>

                  <div v-if="v$.address2.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 50, field: $t('t-address')}) }}
                  </div>

                </div>
              </div>

              <div class="mt-3">
                <label for="name" class="form-label">{{ $t("t-address3") }}</label>
                <input type="text" :maxlength="50" v-model="v$.address3.$model"
                       :class="{ 'is-invalid': invalid && v$.address3.$invalid ,'highlight': (compareObject && state.resultCompare.address3) }"
                       :disabled="disabled" class="form-control">

                <div v-if="v$.address3.$error && invalid" class="invalid-feedback">
                  <div v-if="v$.address3.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 50, field: $t('t-address3')}) }}
                  </div>
                </div>

              </div>

            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-map-information") }}<span
                class="text-danger">*</span></label>
              <input type="text" class="form-control"
                     @input="checkLocation"
                     :class="{ 'is-invalid': (v$l.mapInfomation.$error && invalid) || invalidLocation,'highlight': (compareObject && state.resultCompare.mapInfo) }"
                     v-model="v$l.mapInfomation.$model" :disabled="disabled">

              <div v-if="(v$l.mapInfomation.$error && invalid) || invalidLocation" class="invalid-feedback">

                <div v-if="v$l.mapInfomation.required.$invalid">
                  {{ $t('field_required', {field: $t('map-information')}) }}
                </div>

                <div v-else-if="v$l.mapInfomation.location.$invalid">
                  {{ $t('invalid_location') }}
                </div>

                <div v-else-if="invalidLocation">
                  {{ $t('msg.invalid_field', {field: $t('map-information')}) }}
                </div>

                <div v-else-if="v$l.mapInfomation.isPassValidate.$invalid">
                  {{ v$l.mapInfomation.isPassValidate.$message }}
                </div>
                <div v-else-if="v$l.mapInfomation.customMsg.$invalid">
                  {{ v$l.mapInfomation.customMsg.$message }}
                </div>

              </div>

            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-phone-number") }}</label>
              <input type="text" :maxlength="17" v-model="v$.phone.$model"
                     :class="{ 'is-invalid': invalid && v$.phone.$invalid, 'highlight': (compareObject && state.resultCompare.phone) }"
                     class="form-control" :disabled="disabled">

              <div v-if="v$.phone.$error && invalid" class="invalid-feedback">
                <div v-if="v$.phone.phoneNumber.$invalid">
                  {{ $t('invalid-phone', {field: $t('t-phone-number')}) }}
                </div>

                <div v-if="!v$.phone.phoneNumber.$invalid && v$.phone.minLength.$invalid">
                  {{ $t('field_minlength', {minlength: 10, field: $t('t-phone-number')}) }}
                </div>
              </div>

            </div>

            <div class="mt-3">
              <div>
                <label for="name" class="form-label">{{ $t("t-store-thumbnail-image") }}</label>
                <DropZone :highlight="compareObject && state.resultCompare.image" :prop-name-file="facilityImageShow"
                          type="file-input" @selectedFile="selectedFile" :disabled="disabled" :show-error-inside="false"
                          @errorUpload="handleErrorUpload"/>
                <DropZone v-if="!facilityImage" :type="'drop-file'" @selectedFile="selectedFile" :disabled="disabled"
                          :show-error-inside="false" @errorUpload="handleErrorUpload"/>

                <img :src="facilityImage" v-if="facilityImage" alt="facilityForm-image" class="w-100">
              </div>
              <div class="error-text-show" v-if="state.errorMessageUpload">
                {{ state.errorMessageUpload }}
              </div>
            </div>
          </div>

          <div class="col-xxl-4 col-md-4">

            <div class="" :class="{ 'is-invalid': invalidHours }">
              <label for="name" class="form-label">{{ $t("t-business-hours") }}<span
                class="text-danger">*</span></label>

              <div v-for="(hour, index) in state.businessHours" :key="hour.type" class="input-group mt-2"
                   :class="[{'highlight': compareObject && (state.resultCompare[index === 0 ? 'checkTimeOpenNormal': (index === 1 ? 'checkTimeOpenWeek' : 'checkTimeOpenHoliday')]) ||
                   (state.resultCompare[index === 0 ? 'openTime' : (index === 1 ? 'openTimeWeek' : 'openTimeHoliday')] || state.resultCompare[index === 0 ? 'closeTime' : (index === 1 ? 'closeTimeWeek' : 'closeTimeHoliday')])}]"
              >
                <div class="input-group-text">
                  <input v-model="hour.checked" :disabled="disabled" class="form-check-input mt-0" type="checkbox"
                         value=""
                         aria-label="Checkbox for following text input">
                </div>
                <div class="form-control show-highlight">
                  <div class="flex">
                    <div class="mr-3 flex align-items-center">{{ hour.text }}</div>
                    <div class="mx-3 flex flex-wrap align-items-center justify-content-center">
                      <cleave
                        :disabled="disabled"
                        v-model="hour.start"
                        :options="state.hourtimePicker"
                        :raw="false"
                        placeholder="hh:mm"
                        class="form-control flatpickr-input w-80-px"
                        :class="{'highlight': compareObject && (state.resultCompare[index === 0 ? 'openTime' : (index === 1 ? 'openTimeWeek' : 'openTimeHoliday')])}"
                        @change="changeTime(index, 'start')"
                        @blur="changeTime(index, 'start')"
                      />
                      <div class="mx-3">-</div>
                      <TimeCustom
                        :disabled="disabled"
                        class="w-80-px"
                        :propValue="hour.end"
                        @updateInput="(value) => state.businessHours[index].end = value"
                      />
                      <!--                      <cleave
                                            :disabled="disabled"
                                            v-model="hour.end"
                                            :options="state.hourtimePicker"
                                            placeholder="hh:mm"
                                            :raw="false"
                                            @change="changeTime(index, 'end')"
                                            @blur="changeTime(index, 'end')"
                                            class="form-control flatpickr-input w-80-px"
                                            :class="{'highlight': compareObject && (state.resultCompare[index === 0 ? 'closeTime' : (index === 1 ? 'closeTimeWeek' : 'closeTimeHoliday')])}"
                                            />-->
                    </div>
                  </div>
                </div>
                <div class="text-danger error-text-show" v-if="!hour.isPass">
                  終了時間が開始時間より前です。
                </div>
              </div>

            </div>

            <div v-if="invalidHours" class="invalid-feedback">
              {{ $t('field_select_required', {field: $t('t-business-hours')}) }}
            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-regular-holiday") }}</label>

              <div v-for="hour in state.regularHoliday" :key="hour.type" class="input-group mt-2"
                   :class="[{'highlight': compareObject && state.resultCompare.holiday[hour.date], 'highlight-extent': hour.type === 'OTHER_DAY' ? state.resultCompare.holiday['OTHER_DAY'] : false}]"
              >
                <div class="input-group-text">
                  <input v-model="hour.checked" :disabled="disabled" class="form-check-input mt-0" type="checkbox"
                         value=""
                         aria-label="Checkbox for following text input">
                </div>
                <div class="form-control show-highlight">
                  <div class="flex align-items-center">
                    <div v-if="hour.text" class="mr-3 w-40-px">{{ $t(hour.text) }}</div>
                    <input v-if="hour.type === 'OTHER_DAY'" :maxlength="255" :disabled="disabled" type="text"
                           v-model="hour.date" class="form-control ml-3"/>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="col-xxl-4 col-md-4">

            <div>
              <label for="facilityForm-costSt" class="form-label">
                {{ $t("t-price") }}<span class="text-danger">*</span>
              </label>

              <div class="form-icon right ">
                <input :disabled="disabled" type="text" v-maska="priceMaska"
                       :class="{ 'is-invalid': invalid && v$.cost.$invalid, 'highlight': (compareObject && state.resultCompare.cost) }"
                       v-model="v$.cost.$model" class="form-control"/>

                <i v-if="!v$.cost.$invalid || !invalid">{{ $t("t-unit") }}</i>

                <div v-if="v$.cost.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.cost.required.$invalid">
                    {{ $t('field_required', {field: $t('t-price')}) }}
                  </div>

                </div>
              </div>

            </div>

            <div class="mt-3">
              <label for="facilityForm-costSt" class="form-label">
                {{ $t("t-fee") }}
              </label>
              <textarea :maxlength="255" v-model="v$.costStr.$model"
                        :class="{ 'is-invalid': invalid && v$.costStr.$invalid, 'highlight': (compareObject && state.resultCompare.costStr) }"
                        :disabled="disabled" class="form-control mt-2" id="facilityForm-costSt"
                        rows="3"></textarea>

              <div v-if="v$.costStr.$error && invalid" class="invalid-feedback">

                <div v-if="v$.costStr.maxLength.$invalid">
                  {{ $t('field_maxlength', {maxlength: 255, field: $t('t-fee')}) }}
                </div>

              </div>
            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-registered-facility-category") }}</label>

              <div v-for="c in state.facilityCategory" :key="c.value" class="input-group mt-2"
                   :class="[{'highlight': (compareObject && state.resultCompare[c.value])}]">
                <div class="input-group-text">
                  <input v-model="facilityForm[c.value]" :disabled="disabled" class="form-check-input mt-0"
                         type="checkbox" value=""
                         aria-label="Checkbox for following text input">
                </div>
                <div class="form-control show-highlight">
                  <div class="flex">
                    <div class="mr-3">{{ c.text }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-remarks") }}</label>
              <textarea :maxlength="255"
                        :class="{ 'is-invalid': invalid && v$.description.$invalid, 'highlight': (compareObject && state.resultCompare.description)}"
                        class="form-control" v-model="v$.description.$model" :disabled="disabled"
                        id="exampleFormControlTextarea5"
                        rows="3"></textarea>

              <div v-if="v$.description.$error && invalid" class="invalid-feedback">

                <div v-if="v$.description.maxLength.$invalid">
                  {{ $t('field_maxlength', {maxlength: 255, field: $t('t-remarks')}) }}
                </div>

              </div>
            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("t-SNS account URL") }}</label>

              <div class="input-group" :class="[{'highlight': (compareObject && state.resultCompare.twitter)}]">
                <span class="input-group-text" id="twiter">
                  <i class="mdi mdi-twitter"></i>
                </span>
                <input :maxlength="255" v-model="v$.twitter.$model"
                       :class="{ 'is-invalid': invalid && v$.twitter.$invalid}" :disabled="disabled" type="text"
                       class="form-control show-highlight" placeholder=""
                       aria-label="Username" aria-describedby="twiter">

                <div v-if="v$.twitter.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.twitter.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 255, field: $t('t-SNS account URL')}) }}
                  </div>

                </div>
              </div>

              <div class="input-group mt-2" :class="[{'highlight': (compareObject && state.resultCompare.instagram)}]">
                <span class="input-group-text" id="instagram">
                  <i class="mdi mdi-instagram"></i>
                </span>
                <input :maxlength="255" v-model="v$.instagram.$model"
                       :class="{ 'is-invalid': invalid && v$.instagram.$invalid}" :disabled="disabled" type="text"
                       class="form-control show-highlight" placeholder=""
                       aria-label="Username" aria-describedby="instagram">

                <div v-if="v$.instagram.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.instagram.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 255, field: $t('t-link')}) }}
                  </div>

                </div>

              </div>

              <div class="input-group mt-2" :class="[{'highlight': (compareObject && state.resultCompare.website)}]">
                <span class="input-group-text" id="website">
                  <i class=" bx bx-world"></i>
                </span>
                <input :maxlength="255" v-model="v$.website.$model"
                       :class="{ 'is-invalid': invalid && v$.website.$invalid }" :disabled="disabled" type="text"
                       class="form-control show-highlight" placeholder=""
                       aria-label="Username" aria-describedby="website">

                <div v-if="v$.website.$error && invalid" class="invalid-feedback">

                  <div v-if="v$.website.maxLength.$invalid">
                    {{ $t('field_maxlength', {maxlength: 255, field: $t('t-link')}) }}
                  </div>

                </div>

              </div>

            </div>

            <div class="mt-3">
              <label for="name" class="form-label">{{ $t("playDiscs.setting-connect-jal") }}</label>
              <div class="input-group mt-2">
                <div class="input-group-text">
                  <input :disabled="disabled" v-model="facilityForm['isJAL']" class="form-check-input mt-0"
                         type="checkbox" value=""
                         aria-label="Checkbox for following text input">
                </div>
                <div class="form-control">
                  <div class="flex">
                    <div class="mr-3">{{ $t('playDiscs.JAL-facility') }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computed, onMounted, reactive, ref, watch} from "vue";
import useVuelidate from "@vuelidate/core";
import {facilityService} from "@/services/facility.service";
import {uploadService} from "@/services/upload.service";
import {useStore} from 'vuex';
import {convertMinutesToTimes, getCountMinutes, minutesToTime, timeToMinutes} from "@/helpers/moment";
import {imageURL} from "@/helpers/image";
import Swal from "sweetalert2";
import i18n from '@/i18n'
import {ROLES, RULE_FACILITY, RULE_POST_CODE} from "@/constants";
import {isNumber} from "@/helpers";

// Components
import Cleave from 'vue-cleave-component';
import DropZone from "@/components/widgets/dropZone";
import Loading from "@/components/Loading";
import {generateImageOss} from "@/helpers/axios/formDataRequest";
import {helpers, required} from "@vuelidate/validators";
import {cloneDeep, get, range} from "lodash";
import {formatTime} from "@/state/helpers";
import TimeCustom from "@/components/common/TimeCustom.vue";

export default {
  name: 'FacilityInformation',
  props: {
    facilityTitle: {
      type: String,
      default: i18n.global.t("t-facility-information")
    },
    facility: {
      type: Object,
      default: () => {
      }
    },
    isMyFacility: {
      type: Boolean,
      default: true
    },
    facilityId: {
      type: String || Number,
      required: false
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    facilityIdStr: {
      type: String,
      default: ''
    },
    updateWithEndpoint: {
      type: Boolean,
      default: false
    },
    isDisabledButton: {
      type: Boolean,
      default: false
    },
    onlyValidate: {
      type: Boolean,
      default: false
    },
    compareObject: {
      type: Boolean,
      default: false
    },
    originFacility: {
      type: Object,
      default: () => {
      }
    },
  },
  components: {
    DropZone,
    Loading,
    Cleave,
    TimeCustom
  },
  setup(props, {emit}) {
    let files = ref([]);
    let dropzoneFile = ref(null);
    const invalid = ref(false);
    const invalidPostalCode = ref(false);

    const store = useStore();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || props.isDisabledButton || !currentUser.value);
    const fileUpload = ref(null);

    let state = reactive({
      errorMessageUpload: '',
      loading: false,
      loadingAction: false,
      hourtimePicker: {
        time: true,
        timeFormat: '24',
        timePattern: ['h', 'm'],
        delimiter: ':'
      },
      businessHours: [
        {
          checked: false,
          text: '平日',
          type: 'weekday',
          start: '00:00',
          end: '23:59',
          isPass: true
        },
        {
          checked: false,
          text: '土日',
          type: 'weekend',
          start: '00:00',
          end: '23:59',
          isPass: true
        },
        {
          checked: false,
          text: '祝日',
          type: 'holiday',
          start: '00:00',
          end: '23:59',
          isPass: true
        },
      ],
      regularHoliday: [
        {
          checked: false,
          text: 't-Monday',
          date: '1',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Tuesday',
          descriptionFDO: '',
          date: '2',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Wednesday',
          date: '3',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Thursday',
          date: '4',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Friday',
          date: '5',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Saturday',
          date: '6',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-Sunday',
          date: '7',
          descriptionFDO: '',
          type: "DAY_OF_WEEK",
        },
        {
          checked: false,
          text: 't-holiday',
          descriptionFDO: '',
          date: '8',
          type: "HOLIDAY",
        },
        {
          checked: false,
          text: '',
          descriptionFDO: '',
          date: '',
          type: "OTHER_DAY",
        },
      ],
      facilityCategory: [
        {
          text: '男湯',
          value: 'isHaveRoomMale',
          key: 'SAUNA_ROOM_MALE'
        },
        {
          text: '女湯',
          value: 'isHaveRoomFemale',
          key: 'SAUNA_ROOM_FEMALE'
        }/*,
          {
            text: '館内全体',
            value: 'isHaveRoomOther',
            key: 'OTHER'
          },*/
      ],
      postalCode: {
        first: '',
        last: ''
      },
      facilityId: currentUser?.value?.facilityId || '',
      resultCompare: {
        holiday: {}
      }
    });

    const facilityForm = ref({
      "name": "",
      "description": "",
      "lat": 0,
      "lng": 0,
      "currentPeopleCount": 0,
      "capacity": 100,
      "image": "",
      "phone": "",
      "website": "",
      "twitter": "",
      "instagram": "",
      "address1": "",
      "address2": "",
      "address3": "",
      "address4": "",
      "cost": 0,
      "costStr": "",
      "costDescription": "",
      "surcharge": 0,
      "surchargeDescription": "",
      "openTime": 0,
      "closeTime": 1440,
      "isRegister": false,
      "status": "ACTIVE",
      "isJAL": false,
    });

    const facilityImage = ref('');
    const facilityImageShow = ref('');

    const mapInfomation = ref('');

    const invalidHours = ref(false);

    const invalidLocation = ref(false);

    const invalidFacilityName = ref(false);

    const drop = async (e) => {
      fileUpload.value = e.dataTransfer.files[0];
      files.value.push(fileUpload.value);
    };

    const selectedFile = async (file) => {
      facilityImageShow.value = file.name
      fileUpload.value = file;
      facilityImage.value = URL.createObjectURL(file);
      facilityForm.value.image = await uploadImage()
    };

    const uploadImage = async () => {

      try {
        let res = await uploadService.uploadFacility(fileUpload.value);
        return res?.imageName || '';
      } catch (error) {
        console.log(error);
        return '';
      }
    }

    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    // rules

    const priceMaska = range(1, 11).map(n => `${Array(n).fill('#').join('')}`)

    const location = helpers.regex(/[0-9\d.\d,\s,]*$/);

    const ruleLocation = {
      mapInfomation: {
        location,
        required,
        isPassValidate: computed(() => ({
          $message() {
            return i18n.global.t('msg.invalid_field', {field: i18n.global.t('t-map-information')})
          },
          $validator(val) {
            const matchDot = val.match(/\./g);
            const matchComma = val.match(/,/g);

            return (matchDot && matchDot.length === 2) && (matchComma && matchComma.length === 1);
          }
        })),
        customMsg: computed(() => ({
          $message() {
            return i18n.global.t('msg.msg_057')
          },
          $validator(val) {
            const matchDot = val.match(/\./g);
            const matchComma = val.match(/,/g);
            if ((matchDot && matchDot.length === 2) && (matchComma && matchComma.length === 1)) {
              const [lat, long] = val.split(',').map(t => Number(t))
              if ((lat >= -90 && lat <= 90) && (long >= -180 && long <= 180)) {
                return true
              }
            }

            return false
          }
        }))
      }
    }

    const v$l = useVuelidate(ruleLocation, {mapInfomation});

    const v$ = useVuelidate(RULE_FACILITY, facilityForm);

    const v$p = useVuelidate(RULE_POST_CODE, state.postalCode);

    watch(() => [state.businessHours], () => {
      checkValidateBusinessHours()
    }, {
      deep: true
    })

    const getFacility = () => {
      try {
        state.loading = true;
        let facilityRes = props.facility;

        if (facilityRes) {
          state.facility = facilityRes;
          facilityForm.value = facilityRes;
          if (facilityRes?.image) {
            facilityImage.value = generateImageOss(facilityRes?.image);
          }
          const splitName = facilityRes?.image ? facilityRes?.image.split('/') : [];

          facilityImageShow.value = splitName.length ? splitName[splitName.length - 1] : '';
          // Set default state postalCode
          if (facilityRes && facilityRes.postalCode) {
            let postalCodeArr = facilityRes.postalCode.split('-');
            state.postalCode.first = postalCodeArr[0] || '';
            state.postalCode.last = postalCodeArr[1] || '';
          }

          // Set default state regularHoliday
          let datesOther = ``;
          if (facilityRes && facilityRes.fixedDayOff && facilityRes.fixedDayOff.length > 0) {
            for (let index = 0; index < facilityRes.fixedDayOff.length; index++) {
              const element = facilityRes.fixedDayOff[index];

              if (element.type === 'DAY_OF_WEEK') {
                let findIndexNormal = state.regularHoliday.findIndex(r => r.date === element.date);
                if (findIndexNormal > -1) {
                  let re = {...state.regularHoliday[findIndexNormal], checked: true, date: element.date};
                  state.regularHoliday[findIndexNormal] = re;
                }
              }


              if (element.type === 'OTHER_DAY') {
                datesOther = [...datesOther, element.date];
              }

            }
          }

          // Set default state other day
          let findIndexOther = state.regularHoliday.findIndex(r => (r.type === 'OTHER_DAY'));
          if (findIndexOther > -1) {
            let re = {
              ...state.regularHoliday[findIndexOther],
              checked: facilityRes.checkOtherDay,
              date: datesOther ? datesOther.join(',') : ''
            };
            state.regularHoliday[findIndexOther] = re;
          }

          // Set default state holoday
          let findIndexHoliday = state.regularHoliday.findIndex(r => (r.type === 'HOLIDAY'));
          if (findIndexHoliday > -1) {
            let re = {...state.regularHoliday[findIndexHoliday], checked: facilityRes.checkHoliday};
            state.regularHoliday[findIndexHoliday] = re;
          }

          // Set default state businessHours
          for (let i = 0; i < state.businessHours.length; i++) {
            let business = state.businessHours[i];
            if (business.type === 'weekday') {
              business.start = convertMinutesToTimes(facilityRes.openTime);
              business.end = minutesToTime(facilityRes.closeTime);
              business.checked = facilityRes.checkTimeOpenNormal;
            }

            if (business.type === 'weekend') {
              business.start = convertMinutesToTimes(facilityRes.openTimeWeek);
              business.end = minutesToTime(facilityRes.closeTimeWeek);
              business.checked = facilityRes.checkTimeOpenWeek;
            }

            if (business.type === 'holiday') {
              business.start = convertMinutesToTimes(facilityRes.openTimeHoliday);
              business.end = minutesToTime(facilityRes.closeTimeHoliday);
              business.checked = facilityRes.checkTimeOpenHoliday;
            }
          }

          if (facilityRes) {
            let lat = facilityRes?.lat || '';
            let lng = facilityRes?.lng || '';
            if (lat && lng) {
              mapInfomation.value = `${lat},${lng}`;
            } else {
              mapInfomation.value = lat || lng || '';
            }
          }
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;

        console.log('======== error')
      }
    };

    const checkLocation = () => {
      let formData = {...facilityForm.value};
      if ((mapInfomation.value.match(/,/g) || []).length > 1) {
        invalidLocation.value = true;
        return;
      }

      formData.lat = mapInfomation.value?.split(',')[0]?.replace(/\s/g, '') || '';
      formData.lng = mapInfomation.value?.split(',')[1]?.replace(/\s/g, '') || '';

      if (!formData.lat || !formData.lng) {
        invalidLocation.value = true;
        return;
      } else {
        if ((formData.lat.match(/\./g) || []).length > 1 || (formData.lng.match(/\./g) || []).length > 1) {
          invalidLocation.value = true;
          return;
        }

        if (!isNumber(formData.lat) || !isNumber(formData.lng)) {
          invalidLocation.value = true;
          return;
        }
      }
      invalidLocation.value = false;
    }

    const getPostalCode = async () => {
      try {
        let postalCode = (state.postalCode.first + "" + state.postalCode.last);
        if (state.postalCode.first && state.postalCode.last) {
          let res = await facilityService.getPostalCodeInfo({zipcode: postalCode});
          console.log(res, '====');
          if (res) {
            if (res.results && res.results.length > 0) {
              facilityForm.value.address1 = `${res?.results[0]?.address2} ${res?.results[0]?.address3}`;
              facilityForm.value.address4 = `${res?.results[0]?.address1}`;

            } else {
              facilityForm.value.address1 = '';
              facilityForm.value.address4 = '';
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      if (!props.isCreate) {
        getFacility();
      }
      if (props.compareObject) {
        handleCompareObject()
      }
    });

    const generateMapInfo = (facility) => {
      let lat = facility?.lat || '';
      let lng = facility?.lng || '';

      let mapInfo = ''
      if (lat && lng) {
        mapInfo = `${lat},${lng}`;
      } else {
        mapInfo = lat || lng || '';
      }

      return mapInfo
    }

    const handleCompareObject = () => {
      const newFacility = cloneDeep(facilityForm.value);
      const originFacility = cloneDeep(props.originFacility);
      delete originFacility.rooms;
      delete originFacility.facilityServices;

      const mapNew = generateMapInfo(newFacility);
      const mapOrigin = generateMapInfo(originFacility);
      state.resultCompare['mapInfo'] = mapNew !== mapOrigin;

      const fixDayOffNew = get(newFacility, 'fixedDayOff', []);
      const fixDayOffOrigin = get(originFacility, 'fixedDayOff', []);
      state.regularHoliday.forEach(itemHoliday => {
        if (itemHoliday.type !== 'OTHER_DAY') {
          const findInNew = fixDayOffNew.findIndex(itemNew => itemNew.date == itemHoliday.date && itemNew.type == itemHoliday.type);
          const findInOrigin = fixDayOffOrigin.findIndex(itemOrigin => itemOrigin.date == itemHoliday.date && itemOrigin.type == itemHoliday.type);
          state.resultCompare['holiday'][itemHoliday.date] = !((findInNew < 0 && findInOrigin < 0) || (findInNew >= 0 && findInOrigin >= 0))
        } else {
          const otherNew = fixDayOffNew.find(item => item.type === 'OTHER_DAY');
          const otherOrigin = fixDayOffOrigin.find(item => item.type === 'OTHER_DAY');

          const dateNew = get(otherNew, 'date', '');
          const dateOrigin = get(otherOrigin, 'date', '');
          state.resultCompare['holiday']['OTHER_DAY'] = !(dateNew == dateOrigin)
        }
      });

      const postalCodeNew = get(newFacility, 'postalCode', '');
      const postalCodeOrigin = get(originFacility, 'postalCode', '');
      const [new1, new2] = postalCodeNew.split('-');
      const [origin1, origin2] = postalCodeOrigin.split('-');

      state.resultCompare['start'] = new1 !== origin1;
      state.resultCompare['end'] = new2 !== origin2;

      Object.keys(newFacility).forEach(key => {
        const typeObject = typeof originFacility[key]
        if (typeObject !== 'object' && typeObject !== 'undefined') {
          state.resultCompare[key] = newFacility[key] != originFacility[key];
        }
      })
    }
    const checkValidateBusinessHours = () => {
      invalidHours.value = false;
      const businessHours = state.businessHours.filter(item => item.checked);
      if (businessHours.length === 0) {
        return false;
      }
      return businessHours.every(b => b.checked && (b.start !== '' && b.end !== ''))
    }

    const validateTimeBusinessHours = () => {
      const temp = cloneDeep(state.businessHours)
      for (let i = 0; i < temp.length; i++) {
        const item = temp[i];
        const start = timeToMinutes(item.start);
        const end = timeToMinutes(item.end);

        if (item['checked']) {
          temp[i]['isPass'] = start < end;
        } else {
          temp[i]['isPass'] = true;
        }
      }

      state.businessHours = temp
    }

    const onSubmit = async () => {
      validateTimeBusinessHours();
      v$.value.$touch();
      v$l.value.$touch();
      v$p.value.$touch();

      invalid.value = v$.value.$invalid || v$l.value.$invalid || v$p.value.$invalid || invalidLocation.value;
      invalidPostalCode.value = v$p.value.$invalid;

      // invalidHours
      if (!checkValidateBusinessHours()) {
        invalidHours.value = true;
        emit('handleOnlyValidateFacility', {validate: false});

        return;
      }

      if (!v$.value.$invalid && !v$l.value.$invalid && !v$p.value.$invalid && state.businessHours.every(item => item.isPass)) {
        try {
          let formData = {...facilityForm.value};
          let postalCode = Object.keys(state.postalCode).map(function (k) {
            return state.postalCode[k]
          })?.join("-");
          formData.postalCode = postalCode !== '-' ? postalCode : '';
          formData.cost = formData.cost.toString();

          if ((mapInfomation.value.match(/,/g) || []).length > 1) {
            invalidLocation.value = true;
            emit('handleOnlyValidateFacility', {validate: false});

            return;
          }

          formData.lat = mapInfomation.value?.split(',')[0]?.replace(/\s/g, '') || '';
          formData.lng = mapInfomation.value?.split(',')[1]?.replace(/\s/g, '') || '';

          if (!formData.lat || !formData.lng) {
            invalidLocation.value = true;
            emit('handleOnlyValidateFacility', {validate: false});

            return;
          } else {
            if ((formData.lat.match(/\./g) || []).length > 1 || (formData.lng.match(/\./g) || []).length > 1) {
              invalidLocation.value = true;
              return;
            }

            if (!isNumber(formData.lat) || !isNumber(formData.lng)) {
              invalidLocation.value = true;
              emit('handleOnlyValidateFacility', {validate: false});

              return;
            }

            formData.lat = Number(formData.lat)
            formData.lng = Number(formData.lng)
          }

          for (let i = 0; i < state.businessHours.length; i++) {
            let business = state.businessHours[i];
            if (business.type === 'weekday') {
              formData.openTime = getCountMinutes('00:00', business.start);
              formData.closeTime = timeToMinutes(business.end);
              formData.checkTimeOpenNormal = business.checked;
            }

            if (business.type === 'weekend') {
              formData.checkTimeOpenWeek = business.checked;
              formData.openTimeWeek = getCountMinutes('00:00', business.start);
              formData.closeTimeWeek = timeToMinutes(business.end);
            }

            if (business.type === 'holiday') {
              formData.checkTimeOpenHoliday = business.checked;
              formData.openTimeHoliday = getCountMinutes('00:00', business.start);
              formData.closeTimeHoliday = timeToMinutes(business.end);
            }
          }

          let fixedDayOffDay = [...state.regularHoliday].filter(r => r.checked && r.type !== 'HOLIDAY' && r.type !== 'OTHER_DAY').map(r => {
            let re = {...r};
            delete re.checked;
            return {
              ...re,
            }
          });

          let holidayData = [...state.regularHoliday].find(s => s.type === 'HOLIDAY');
          formData.checkHoliday = holidayData?.checked || false;

          let otherDayData = [...state.regularHoliday].find(s => s.type === 'OTHER_DAY');
          let fixedDayOffOther = [];
          if (otherDayData) {
            let dates = otherDayData.date.split('、').join(',').split(',');
            for (let index = 0; index < dates.length; index++) {
              const date = dates[index].replace(/\s/g, '');
              if (date) {
                let dayOffHoliday = {...otherDayData, date: date};
                delete dayOffHoliday.checked;
                fixedDayOffOther = [
                  ...fixedDayOffOther,
                  dayOffHoliday
                ]
              }
            }
            formData.checkOtherDay = otherDayData?.checked || false;
          }

          formData.fixedDayOff = [...fixedDayOffDay, ...fixedDayOffOther].filter(f => f.date);

          emit('handleOnlyValidateFacility', {validate: true});

          if (props.updateWithEndpoint) {
            emit('onUpdateWidthEndpoint', {payload: formData, validate: true})
            return;
          }

          if (props.isCreate) {
            emit('createFacility', {payload: formData, validate: true});
            return;
          } else {
            state.loadingAction = true;

            if (props.isMyFacility) {
              await facilityService.updateMyFacility(formData);
            } else {
              await facilityService.updateFacility(props.facilityId, formData);
            }
            store.commit('settings/SET_REFRESH_HEADER', true);
            await Swal.fire({
              title: "",
              text: i18n.global.t('msg.saved'),
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            await store.dispatch('auth/getUserProfile')
            if (props.isMyFacility) {
              emit('reload', props.facilityId);
            } else {
              emit('reload');
            }
            state.loadingAction = false;
          }
        } catch (error) {
          let message = error?.response?.data?.message || '';
          if (message.includes('already exists')) {
            invalidFacilityName.value = true;
          } else {
            await Swal.fire({
              title: "",
              text: message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }

          state.loadingAction = false;

          console.log(error);
        }
      } else {
        emit('handleOnlyValidateFacility', {validate: false});
      }

      if (props.isCreate) {
        emit('createFacility', {payload: {}, validate: false})
      }
    }

    const getImageURL = (url) => {
      return imageURL(url);
    }

    const setLoadingAction = (value) => {
      state.loadingAction = value
    }

    const handleErrorUpload = (value) => {
      state.errorMessageUpload = value
    }

    const changeTime = (index, type = 'start') => {
      state.businessHours[index][type] = formatTime(state.businessHours[index][type])

      //state.businessHours[index][type] = state.businessHours[index][type] === '' ? "00:00" : state.businessHours[index][type];
    }

    return {
      state,
      facilityForm,
      v$, v$l, v$p,
      dropzoneFile, files, facilityImage, disabled, mapInfomation, invalid, invalidPostalCode,
      invalidHours,
      invalidLocation,
      invalidFacilityName,
      facilityImageShow,
      checkLocation,
      drop, selectedFile, onSubmit, getImageURL, getPostalCode,
      setLoadingAction,
      handleErrorUpload,
      priceMaska,
      checkValidateBusinessHours,
      changeTime
    };
  },
}
</script>

<style lang="scss" scoped>
.w-80-px {
  width: 80px;
}

.w-40-px {
  width: 40px;
}

.line-header {
  border-bottom: 1px solid var(--vz-border-color) !important;
  padding-top: 10px;
  padding-bottom: 20px;
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}

.highlight-extent, .highlight {
  border-color: #edc86f;

  .input-group-text {
    border-color: #edc86f;
  }

  .show-highlight {
    border-color: #edc86f;
  }
}
</style>
