<template>
  <div class="card card-body facility-filter-information">
    <div class="row table-component">
      <div class="col-xxl-12 col-md-12">
        <div class="align-middle flex justify-between line-header">
          <div class="fs-16 table-header-title flex align-center justify-center">{{ $t('t-facility-filter-information') }}
          </div>
          <div v-if="!isCreate">
            <b-button variant="primary" :disabled="loadingAction || disabled" @click="onSubmit"
                      class="waves-effect waves-light">
              <div class="flex">
                <span v-if="loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
                {{ $t("t-renew") }}
              </div>
            </b-button>
          </div>
        </div>
      </div>

      <div class="col-xxl-12 col-md-12">
        <div v-for="(cate) in categories" :key="cate.id" class="row py-1">
          <div class="col-xxl-12 col-md-12">
            <div>{{ cate.name }}</div>
          </div>

          <div class="col-xxl-12 col-md-12" v-for="(serviceKey, index) in Object.keys(cate.services)"
               :key="`${index}_${cate.id}`">
            <div v-if="serviceKey !== 'null' && serviceKey != cate.name">{{ serviceKey }}</div>

            <div class="row">
              <div class="col-xxl-6 col-md-6" v-for="detail in cate.services[serviceKey]" :key="detail.id">
                <div class="hstack gap-2 flex-wrap w-100">
                  <input v-model="selectedServiceId[`${cate.index}${serviceKey=='null'?'':serviceKey}`]"
                         :disabled="disabled" :type="detail.submitType === 'CHECK_BOX' ? 'checkbox' : 'radio'"
                         @click="handleClickInput(detail.submitType, detail.id, `${cate.index}${serviceKey=='null'?'':serviceKey}`)"
                         class="btn-check" :id="detail.id" :value="detail.id" >
                  <label style="height: 38px" class="btn btn-outline-dark w-100 white-space-pre-line px-0 py-0 d-flex justify-content-center align-items-center" :for="detail.id" :class="{'highlight': getClassHighlight(detail.id)}">{{
                      detail.name
                    }}</label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {ref, onMounted, computed, reactive} from "vue";
import {categoriesService} from "@/services/categories.service";
import {facilityService} from "@/services/facility.service";
import Loading from "@/components/Loading";
import {useStore} from 'vuex';
import {ROLES} from "@/constants";
import Swal from "sweetalert2";
import i18n from '@/i18n'
import {concat, get, groupBy} from "lodash";
import {addDays} from "@fullcalendar/core";

export default {
  name: 'FacilityInformationFilter',
  components: {
    Loading,
  },
  props: {
    facility: {
      type: Object,
      default: () => {}
    },
    isMyFacility: {
      type: Boolean,
      default: true,
      required: false
    },
    facilityId: {
      type: String || Number,
      default: '',
      required: false
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    updateWithEndpoint: {
      type: Boolean,
      default: false
    },
    isDisabledButton: {
      type: Boolean,
      default: false
    },
    compareObject: {
      type: Boolean,
      default: false
    },
    originFacility: {
      type: Object,
      default: () => {}
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    const loading = ref(false);
    const loadingAction = ref(false);
    const categories = ref([]);
    const selectedServiceId = reactive({});
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value.role !== ROLES.FACILITY_MANAGER && currentUser.value.role !== ROLES.ADMIN) || props.isDisabledButton || !currentUser.value);
    const categoriesInit = ref();
    const state = reactive({
      compareServices: []
    });

    const params = ref({
      page: 1,
      pageSize: 20
    });

    const formatSelectedCategories = (listSelected, categories) => {
      listSelected.forEach(categoryID => {
        categories.forEach(item => {
          let servicesKey = Object.keys(item.services);
          servicesKey.forEach((key) => {
            const findItem = item.services[key].find(v => v.id === categoryID);

            if (findItem) {
              selectedServiceId[`${item.index}${key === 'null' ? '' : key}`] = findItem.submitType === 'CHECK_BOX' ? [...selectedServiceId[`${item.index}${key === 'null' ? '' : key}`], categoryID] : categoryID;
            }
          })
        })
      })
    }

    const getCategories = async () => {
      try {
        loading.value = true;
        let res = await categoriesService.getCategories(params.value);
        categoriesInit.value = res.data;

        if (res) {
          const temp = categoriesInit.value.map(item => {
            const services = groupBy(item.services, 'subCategory');
            if (item.name == "タオル・館内着・サウナマット") {
              delete services['null'];
            }
            return {
              ...item,
              services: services
            }
          })

          temp.forEach(item => {
            Object.keys(item.services).forEach(key => {
              selectedServiceId[`${item.index}${key === 'null' ? '' : key}`] = []
            })

          })

          categories.value = temp;
          const facility = props?.facility || null;
          const facilityServices = facility?.facilityServices || [];
          if (facilityServices && facilityServices.length) {
            const listSelected = facilityServices.map(s => {
              return typeof s === 'string' ? s : s.serviceId;
            })
            formatSelectedCategories(listSelected, temp)
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    }

    onMounted(() => {
      getCategories();
      if (props.compareObject) {
        handleCompareService();
      }
    });

    const handleCompareService = () => {
      const serviceNew = get(props.facility, 'facilityServices', []);
      const serviceOrigin = get(props.originFacility, 'facilityServices', []);

      const tempId = [];
      serviceNew.concat(serviceOrigin).forEach(item => {
        typeof item === 'object' ? tempId.push(item.serviceId) :  tempId.push(item)
      })

      const result = [];
      const groupId = groupBy(tempId);
      Object.values(groupId).map(value => {
        if (value.length === 1) {
          result.push(value[0])
        }
      })
      state.compareServices = result;
    }

    const setLoadingAction = (value) => {
      loadingAction.value = value;
    }

    const concatValue = () => {
      let test = [];
      Object.values(selectedServiceId).forEach(item => {
        test = concat(test, item)
      })

      return test.filter(v => v !== '');
    }

    const onSubmit = async () => {
      try {
        if (props.updateWithEndpoint) {
          emit('onUpdateWidthEndpoint', concatValue())
          return;
        }

        if (props.isCreate) {
          emit('createService', concatValue());
          return;
        }

        loadingAction.value = true;
        if (props.isMyFacility) {
          await facilityService.updateFacilityServices({arrayServiceId: concatValue()});
        } else {
          await facilityService.updateFacilityServicesByFacilityID({arrayServiceId: concatValue()}, props.facilityId);
        }

        //selectedServiceId.value = res.map(r => r.serviceId);
        loadingAction.value = false;
        Swal.fire({
          title: "",
          text: i18n.global.t('msg.saved'),
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {
        let message = error?.response?.data?.message || '';
        Swal.fire({
          title: "",
          text: message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        loadingAction.value = false;
      }
    }

    const handleClickInput = (type, value, path) => {
      if (type === 'RADIO_BUTTON' && selectedServiceId[path] === value) {
        selectedServiceId[path] = ''
      }
    }

    const getClassHighlight = (idService) => {
      const findIndex = state.compareServices.findIndex(item => item == idService);

      return props.compareObject && findIndex >= 0
    }

    return {
      state,
      loading,
      loadingAction,
      categories,
      selectedServiceId,
      disabled,
      categoriesInit,
      onSubmit,
      setLoadingAction,
      handleClickInput,
      getClassHighlight
    };
  }
}
</script>

<style lang="scss">
.facility-filter-information {
  .box-info {
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    padding: 5px;
    cursor: pointer;
  }
}

.line-header {
  border-bottom: 1px solid var(--vz-border-color) !important;
  padding-top: 10px;
  padding-bottom: 20px;
}
.highlight {
  border-color: #edc86f !important;
}
</style>
