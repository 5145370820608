<template>
  <div class="card card-body">
    <div class="row table-component">
      <div class="col-xxl-12 col-md-12">
        <div class="align-middle flex justify-between line-header">
          <div class="fs-16 table-header-title flex align-center justify-center">{{$t('t-Management screen setting information')}}</div>
          <div v-if="!isCreate">
            <b-button variant="primary" :disabled="loadingAction || disabled" @click="onSubmit" class="waves-effect waves-light">
              <div class="flex">
                <span v-if="loadingAction" class="d-flex align-items-center mr-2">
                  <Loading />
                </span>
                {{ $t("t-renew") }}
              </div>
            </b-button>
          </div>
        </div>
      </div>

      <div class="col-xxl-12 col-md-12 mt-3">
        <label for="name" class="form-label">{{$t("t-refresh")}}</label>
        <div class="form-icon right " :class="{ 'is-invalid': v$.second && invalid && v$.second.$invalid }">
          <input :disabled="disabled" type="text" v-maska="['', '##########']" v-model="v$.second.$model" class="form-control" id="avgTemperature">
          <i class="font-style-nomal">{{$t('t-second')}}</i>
        </div>
        <div v-if="v$.second.$error && invalid" class="invalid-feedback">
          <div v-if="v$.second.alphaNum.$invalid">
            {{$t('has-integer', { field: $t('t-second') })}}
          </div>
          <div v-else-if="v$.second.minValue.$invalid">
            {{ $t('t-refresh-error') }}
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import Loading from "@/components/Loading";
import useVuelidate from '@vuelidate/core'
import {alphaNum, minValue} from '@vuelidate/validators'
import { useStore } from 'vuex';
import Swal from "sweetalert2";
import i18n from '@/i18n'
import { ROLES } from "../../../../constants";
import { getAutoRefreshSetting } from '@/helpers'

export default {
  name: 'FacilitySettings',
  props: {
    isCreate: {
      type: Boolean,
      default: false
    },
    isDisabledButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loading
  },
  setup(props) {
    const store = useStore();

    const loadingAction = ref(false);
    const invalid = ref(false);
    const second = ref(getAutoRefreshSetting());
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value &&  currentUser.value.role === ROLES.FACILITY_ACCOUNT) || props.isDisabledButton || !currentUser.value);

    const rules = {
      second: {
        minValue: minValue(30),
        alphaNum
      }
    }

    const v$ = useVuelidate(rules, { second });

    const onSubmit = async () => {
      v$.value.$touch();
      invalid.value = v$.value.$invalid;
      if (v$.value.$invalid) {
        return;
      }
      if (props.isCreate) return
      localStorage.setItem('second', second.value);
      store.commit('settings/SET_SECONDS', second.value * 1);
      Swal.fire({
        title: "",
        text: i18n.global.t('msg.saved'),
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    };

    return {
      loadingAction,
      second,
      invalid,
      v$,
      onSubmit,
      disabled
    };
  }
}
</script>

<style scoped lang="scss">
  .line-header {
    border-bottom: 1px solid var(--vz-border-color) !important;
    padding-top: 10px;
    padding-bottom: 20px;
  }
</style>
