<template>
  <div class="card card-body room-sauna-dried">
    <div class="row table-component">
      <div class="col-xxl-12 col-md-12">
        <div class="align-middle flex justify-between line-header mx-2">
          <div class="fs-16 table-header-title flex align-center justify-center">{{ $t(roomTitle) }}</div>
          <div v-if="!isCreate">
            <b-button @click="onSubmit" :disabled="state.loadingAction || disabled || state.stateButton"
                      variant="primary"
                      class="waves-effect waves-light">
              <div class="flex">
                <span v-if="state.loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
                {{ $t('t-renew') }}
              </div>
            </b-button>
          </div>
        </div>
      </div>

      <div class="col-xxl-12 col-md-12 room-container">
        <table class="table align-middle table-nowrap">
          <thead class="">
          <tr>
            <th v-for="column in columns" :key="column.value" class="sort" data-sort="currency_name" scope="col">
              {{ $t(column.title) }}
              <span class="text-danger" v-if="column.required">*</span>
            </th>
          </tr>
          </thead>

          <tbody class="list form-check-all">

          <template v-if="!state.loading">

            <tr v-for="(room, index) of state.rooms" :key="index"
                :class="[{'highlight-new': compareObject && state.compareRooms.findIndex(item => item.id === room.id && item.isNew) >= 0 }]">

              <td v-for="column in columns" :key="column.value">

                <!-- Room id -->
                <template v-if="column.value === 'ID'">
                  <div style="padding-top: 9px"> {{ index + 1 }}</div>
                </template>
                <!-- Select type -->
                <template v-else-if="column.value === 'type'">
                  <div>
                    <select
                      class="form-select select-room-type"
                      aria-label="Default select example"
                      v-model="v$.rooms.$model[index][column.value]"
                      :class="{ 'is-invalid': v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid, 'highlight': getClassHighlight(room, column.value)}"
                      :disabled="disabled"
                    >
                      <option v-for="t in state.roomTypes" :key="t" :value="t">
                        {{ $t(i18nMapLabel[t]) }}
                      </option>
                    </select>
                    <div class="invalid-feedback"
                         v-if="v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid">

                      <div v-if="!v$.rooms.$each.$response.$data[index][column.value].required">
                        {{ $t('field_select_required', {field: $t('facility_category')}) }}
                      </div>

                    </div>
                  </div>
                </template>

                <template v-else-if="column.value === 'name'">
                  <div>
                    <input
                      :maxlength="50"
                      v-model="v$.rooms.$model[index][column.value]"
                      :disabled="true"
                      type="text" class="form-control room-name" id="name">
                  </div>
                </template>

                <template v-else-if="column.value === 'roomName'">
                  <div>
                    <input
                      :maxlength="50"
                      v-model="v$.rooms.$model[index][column.value]"
                      :disabled="disabled"
                      :class="{
                          'is-invalid': (v$.rooms.$each.$response.$data[index][column.value].$invalid || duplicatedNames.includes(v$.rooms.$model[index][column.value]))
                            && invalid,
                             'highlight': getClassHighlight(room, column.value)
                        }"
                      type="text" class="form-control room-name" id="roomName">

                    <div class="invalid-feedback"
                         v-if="(v$.rooms.$each.$response.$data[index][column.value].$invalid
                        || duplicatedNames.includes(v$.rooms.$model[index][column.value])) && invalid">

                      <div v-if="!v$.rooms.$each.$response.$data[index][column.value].required">
                        {{ $t('field_required', {field: getI18n(v$.rooms, index)}) }}
                      </div>

                      <div v-else-if="!v$.rooms.$each.$response.$data[index][column.value].maxLength">
                        {{ $t('field_maxlength', {maxlength: 10, field: $t('t-room-name')}) }}
                      </div>

                      <div v-else-if="duplicatedNames.includes(v$.rooms.$model[index][column.value])">
                        {{ $t('duplicated', {field: $t('t-room-name')}) }}
                      </div>

                    </div>
                  </div>
                </template>

                <template v-else-if="column.value === 'avgTemperature'">
                  <div class="form-icon right">
                    <input
                      @keydown="onKeydownAvgTemperature($event, index)"
                      :maxlength="10"
                      type="text"
                      min="0"
                      v-model="v$.rooms.$model[index][column.value]"
                      :class="{ 'is-invalid': v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid, 'highlight': getClassHighlight(room, column.value) }"
                      :disabled="disabled" class="form-control" id="avgTemperature">

                    <i v-if="!v$.rooms.$each.$response.$data[index][column.value].$invalid || !invalid">℃</i>

                    <div class="invalid-feedback"
                         v-if="v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid">

                      <div v-if="!v$.rooms.$each.$response.$data[index][column.value].requiredIf">
                        {{ $t('field_required', {field: $t('t-average-temperature')}) }}
                      </div>

                      <div v-else-if="!v$.rooms.$each.$response.$data[index][column.value].customDecimal">
                        {{ $t('msg.max_value', {field: $t('t-average-temperature')}) }}
                      </div>
                    </div>

                  </div>
                </template>

                <template v-else-if="column.value === 'capacity'">
                  <div>
                    <div class="form-icon right ">
                      <input
                        v-maska="['-', '#####',' ']"
                        :maxlength="5"
                        type="text"
                        v-model="v$.rooms.$model[index][column.value]"
                        class="form-control"
                        :class="{
                          'is-invalid': (v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid) || state.rooms[index].exception,
                           'highlight': getClassHighlight(room, column.value) }"
                        id="capacity"
                        :disabled="disabled"
                        @change="changeCapacity(index)"
                      >
                      <i
                        v-if="!state.rooms[index].exception && (!v$.rooms.$each.$response.$data[index][column.value].$invalid || !invalid )">名</i>
                      <div class="invalid-feedback-custom" v-if="state.rooms[index].exception">
                        {{ $t('has-integer', {field: $t('t-capacity')}) }}
                      </div>
                      <div class="invalid-feedback"
                           v-if="v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid && state.rooms[index].exception === false">

                        <div v-if="!v$.rooms.$each.$response.$data[index][column.value].required">
                          {{ $t('field_required', {field: $t('t-capacity')}) }}
                        </div>

                        <div v-else-if="!v$.rooms.$each.$response.$data[index][column.value].alphaNum">
                          {{ $t('has-integer', {field: $t('t-capacity')}) }}
                        </div>

                        <div v-if="!v$.rooms.$each.$response.$data[index][column.value].maxLength">
                          {{ $t('field_maxlength', {maxlength: 5, field: $t('t-capacity')}) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else-if="column.value === 'userTabletId'">
                  <select
                    :class="{'highlight': getClassHighlight(room, column.value)}"
                    class="form-select select-room-type"
                    v-model="room[column.value]"
                    :disabled="disabled"
                  >
                    <template v-if="room.user">
                      <option value="">{{ $t('t-no-selection') }}</option>
                      <option v-for="t in [ ...userTablets, room.user ]" :key="t.id" :value="t.id">
                        {{ t.tabletId }}
                      </option>
                    </template>
                    <template v-else>
                      <option value="">{{ $t('t-no-selection') }}</option>
                      <option
                        v-for="t in [ ...userTablets ].filter(u => state.rooms.findIndex((r, i) => r[column.value] === u.id && index !== i) === -1)"
                        :key="t.id" :value="t.id">
                        {{ t.tabletId }}
                      </option>
                    </template>
                  </select>
                </template>

                <template v-else-if="column.value === 'subTypeRested'">
                  <div>
                    <select
                      class="form-select select-room-type"
                      aria-label="Default select example"
                      v-model="v$.rooms.$model[index][column.value]"
                      :class="{ 'is-invalid': v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid, 'highlight': getClassHighlight(room, column.value) }"
                      @change="room.isEditable = true"
                      :disabled="disabled"
                    >
                      <option v-for="t in state.subTypesRested" :key="t" :value="t">
                        {{ $t(t) }}
                      </option>
                    </select>
                    <div class="invalid-feedback"
                         v-if="v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid">

                      <div v-if="!v$.rooms.$each.$response.$data[index][column.value].requiredIf">
                        {{ $t('field_required', {field: $t('facility_category')}) }}
                      </div>

                    </div>

                  </div>
                </template>
                <template v-else-if="column.value === 'useTime'">
                  <div class="d-flex justify-content-center align-items-center">
                    <cleave
                      v-model="room['startTime']"
                      :disabled="disabled"
                      :options="state.hourtimePicker"
                      :raw="false"
                      placeholder="hh:mm"
                      class="form-control flatpickr-input w-80-px"
                      @change="changeRoom(index)"
                      @blur="changeRoom(index)"
                      :class="{
                        'input-error': v$.rooms.$each.$response.$data[index]['startTime'].$invalid && invalid,
                        'highlight': getClassHighlightTime(index, 'startTime') }"
                    />
                    <div class="mx-3">-</div>
                    <TimeCustom
                      :class-highlight="getClassHighlightTime(index, 'endTime') ? 'highlight' : ''"
                      :propValue="room['endTime']"
                      @updateInput="(value) => state.rooms[index]['endTime'] = value"
                      :disabled="disabled"
                    />
                  </div>
                  <div class="error-text-show"
                       v-if="(v$.rooms.$each.$response.$data[index]['startTime'].$invalid || v$.rooms.$each.$response.$data[index]['endTime'].$invalid) && invalid">
                    <div
                      v-if="!v$.rooms.$each.$response.$data[index]['startTime'].required || !v$.rooms.$each.$response.$data[index]['endTime'].required">
                      {{ $t('field_required', {field: $t('t-use-time')}) }}
                    </div>
                  </div>
                </template>

                <template v-else-if="column.value === 'description'">
                  <input
                    type="text" :maxlength="255"
                    :disabled="disabled" @change="room.isEditable = true" class="form-control" id=""
                    :class="{ 'is-invalid': v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid, 'highlight': getClassHighlight(room, column.value) }"
                    v-model="v$.rooms.$model[index][column.value]">

                  <div class="invalid-feedback"
                       v-if="v$.rooms.$each.$response.$data[index][column.value].$invalid && invalid">

                    <div v-if="!v$.rooms.$each.$response.$data[index][column.value].maxLength">
                      {{ $t('field_maxlength', {maxlength: 255, field: $t('t-remarks')}) }}
                    </div>

                  </div>
                </template>

              </td>

              <td class="text-end">
                <b-button @click="onClickDeleteRoom(room, index)" :disabled="disabled" variant="danger"
                          class="r waves-effect waves-light">
                  {{ $t('t-delete') }}
                </b-button>
              </td>
            </tr>

          </template>

          <tr v-else>
            <td colspan="8" class="text-center p-5">
              <Loading/>
            </td>
          </tr>

          <tr v-if="(!state.rooms || state.rooms.length === 0) && !state.loading">
            <td colspan="8" class="text-center text-no-data p-5">
              {{ $t('t-no-data') }}
            </td>
          </tr>

          </tbody>

        </table>

        <div v-if="pagination" class="d-flex justify-content-end mt-3">
          <Pagination :pagination="pagination" @onChangePage="onChangePage"/>
        </div>

        <div class="text-center my-3">
          <b-button @click="onAddNewRoom" :disabled="disabled" variant="dark" class="waves-effect waves-light">
            {{ $t('t-create') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, computed, ref} from "vue";
import {roomService} from "@/services/room.service";
import {userService} from "@/services/user.service";
import {required, helpers, maxLength, alphaNum, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {useStore} from 'vuex';
import Swal from "sweetalert2";
import Loading from "@/components/Loading";
import {ROLES, SENSOR_STATUS, SensorStatus} from "@/constants";
import i18n from '@/i18n'
import Pagination from "@/components/pagination";
import {cloneDeep, get, isNumber} from 'lodash';
import Cleave from 'vue-cleave-component';
import TimeCustom from "@/components/common/TimeCustom.vue";
import {formatTime} from "@/state/helpers";

export default {
  name: "SAUNAROOM",
  computed: {
    SensorStatus() {
      return SensorStatus
    }
  },
  props: {
    roomSubType: {
      type: String,
      default: 'SAUNA_DRIED',
    },
    isMyFacility: {
      type: Boolean,
      default: true,
      required: false
    },
    facilityId: {
      type: String || Number,
      default: '',
      required: false
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    updateWithEndpoint: {
      type: Boolean,
      default: false
    },
    isRoomRequest: {
      type: Boolean,
      default: false
    },
    isDisabledButton: {
      type: Boolean,
      default: false
    },
    onlyValidate: {
      type: Boolean,
      default: false
    },
    compareObject: {
      type: Boolean,
      default: false
    },
    originFacility: {
      type: Object,
      default: () => {
      }
    },
    isFacilityApplication: {
      type: Boolean,
      default: false
    },
    stateUpdateRoom: {
      type: Number,
      default: 0
    },
  },
  components: {
    Loading,
    Pagination,
    Cleave,
    TimeCustom
  },
  setup(props, {emit}) {
    const store = useStore();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value.role !== ROLES.FACILITY_MANAGER && currentUser.value.role !== ROLES.ADMIN) || props.isDisabledButton || !currentUser.value);
    const userTablets = ref([]);
    const invalid = ref(false);
    const pagination = ref();
    const roomTitle = ref('t-sauna-room-information');
    const columns = ref([]);
    const duplicatedNames = ref([]);

    // Set title
    if (props.roomSubType === 'SAUNA_DRIED') {
      roomTitle.value = 't-sauna-room-information';
      columns.value = [
        {
          title: 'ID',
          value: 'ID',
          required: false
        },
        {
          title: 't-registered-facility-category',
          value: 'type',
          required: true
        },
        {
          title: 't-room-number',
          value: 'name',
        },
        {
          title: 'room.dried',
          value: 'roomName',
          required: true
        },
        {
          title: 't-average-temperature',
          value: 'avgTemperature',
          required: true
        },
        {
          title: 't-capacity',
          value: 'capacity',
          required: true,
          dynamicText: (row) => {
            console.log(row)
          },
        },
        {
          title: 't-use-time',
          value: 'useTime',
          required: true
        },
        {
          title: 't-Sensor ID (automatic numbering)',
          value: 'userTabletId'
        }
      ];
    } else if (props.roomSubType === 'SAUNA_RESTED') {
      columns.value = [
        {
          title: 'ID',
          value: 'ID',
          required: false
        },
        {
          title: 't-registered-facility-category',
          value: 'type',
          required: true
        },
        {
          title: 't-room-number',
          value: 'name',
        },
        {
          title: 'room.rested',
          value: 'roomName',
          required: true
        },
        {
          title: 't-type',
          value: 'subTypeRested',
          required: true
        },
        {
          title: 't-capacity',
          value: 'capacity',
          required: true
        },
        {
          title: 't-use-time',
          value: 'useTime',
          required: true
        },
        {
          title: 't-remarks',
          value: 'description'
        }
      ];
      roomTitle.value = 't-break-information';
    } else {
      columns.value = [
        {
          title: 'ID',
          value: 'ID',
        },
        {
          title: 't-registered-facility-category',
          value: 'type',
          required: true
        },
        {
          title: 't-room-number',
          value: 'name',
        },
        {
          title: 'room.wet',
          value: 'roomName',
          required: true
        },
        {
          title: 't-average-temperature',
          value: 'avgTemperature',
          required: true
        },
        {
          title: 't-capacity',
          value: 'capacity',
          required: true
        },
        {
          title: 't-use-time',
          value: 'useTime',
          required: true
        },
        {
          title: 't-remarks',
          value: 'description'
        }
      ];
      roomTitle.value = 't-water-bath-information';
    }

    const i18nMapLabel = {
      SAUNA_ROOM_MALE: 't-room-male',
      SAUNA_ROOM_FEMALE: 't-room-female',
      // OTHER: 'OTHER'
    }

    const state = reactive({
      loading: false,
      loadingAction: false,
      params: {
        page: 1,
        pageSize: 5,
        subType: props.roomSubType,
        facilityId: props.isMyFacility ? (currentUser?.value?.facilityId || '') : props.facilityId
      },
      pagination: null,
      rooms: [],
      addedRooms: [],
      addedRoomsForm: [],
      roomsForm: [],
      roomId: null,
      roomTypes: ['SAUNA_ROOM_MALE', 'SAUNA_ROOM_FEMALE'],
      roomSubTypes: ['SAUNA_DRIED', 'SAUNA_WET', 'SAUNA_RESTED', 'RESTAURANT_ROOM', 'OTHER_ROOM_REST_SPACE', 'OTHER_ROOM_OTHER'],
      subTypesRested: ['OUTSIDE_RESTED', 'INSIDE_RESTED'],
      room: {
        name: '',
        description: '',
        facilityId: props.isMyFacility ? (currentUser?.value?.facilityId || '') : props.facilityId,
        subType: props.roomSubType,
        type: '',
        capacity: '-',
        currentPeopleCount: '',
        avgTemperature: null,
        openTime: 0,
        closeTime: 144,
        startTime: '00:00',
        endTime: '23:59',
        isRegister: true,
        userTabletId: '',
        roomName: '',
        statusSensor: '',
        exception: false,
      },
      hourtimePicker: {
        time: true,
        timeFormat: '27',
        timePattern: ['h', 'm'],
        delimiter: ':'
      },
      compareRooms: [],
      stateButton: false
    });

    const getI18n = (rooms, index) => {
      const subType = rooms.$model[index].subType;
      if (subType == 'SAUNA_DRIED') {
        return i18n.global.t('room.dried')
      } else if (subType == 'SAUNA_RESTED') {
        return i18n.global.t('room.rested')
      } else {
        return i18n.global.t('room.wet')
      }
    }

    // Function get all Rooms by room subtype
    const getRooms = async () => {
      let res = null;
      try {
        state.loading = true;
        state.rooms.length = 0;
        state.rooms.length = 0;

        if (props.isRoomRequest) {
          if (props.facilityId) {
            const params = cloneDeep(state.params);

            params['facilityId'] = props.facilityId;
            res = await roomService.getRoomsFacilityRequest(props.facilityId, params);

          }
        } else {
          if (!props.isMyFacility) {
            state.params.facilityId = props.facilityId
          }
          res = await roomService.getRooms(state.params);
        }
      } catch (e) {
        console.log(e, 'error get rooms facility app')
      } finally {
        state.loading = false;
      }

      if (res !== null) {
        state.pagination = res?.pagination || null;
        pagination.value = res?.pagination || null;
        let data = res?.data || res || [];
        state.rooms = data?.map(d => {
          d.capacity = (d.capacity == null || !isNumber(+d.capacity)) ? '-' : +d.capacity;

          return {
            ...d,
            userTabletId: d?.user?.id || '',
            isRegister: false,
            isEditable: false
          }
        }) || [];

        if (props.compareObject) {
          handleCompareObject();
        }

      }
    };

    // Validate rules
    const rules = {
      rooms: {
        $each: helpers.forEach({
          type: {required},
          capacity: {required, maxLength: maxLength(5)},
          avgTemperature: {
            maxLength: maxLength(10),
            requiredIf: requiredIf(function () {
              if (props.roomSubType === 'SAUNA_RESTED') {
                return false;
              }
              return true;
            }),
            customDecimal: {
              $message() {
                return '';
              },
              $validator(val) {
                console.log(+val)
                if (!val || (/^\d{0,3}(\.\d{1})?$/.test(val) && +val <= 200)) {
                  return true;
                }
                return false
              }
            }
          },
          description: {maxLength: maxLength(255)},
          subTypeRested: {
            requiredIf: requiredIf(function () {
              if (props.roomSubType === 'SAUNA_RESTED') {
                return true;
              }
              return false;
            }),
          },
          roomName: {required},
          endTime: {required},
          startTime: {required},
        }),
      },
      addedRooms: {
        $each: helpers.forEach({
          facilityId: {required},
          type: {required},
          capacity: {required, maxLength: maxLength(5), alphaNum},
          avgTemperature: {required, maxLength: maxLength(3), alphaNum}
        }),
      }
    };


    let v$ = useVuelidate(rules, state);

    // Function added room record to addedRooms
    const onAddNewRoom = () => {
      let room = {...state.room};
      if (props.roomSubType === 'SAUNA_RESTED') {
        room = {...state.room, subTypeRested: 'OUTSIDE_RESTED'};
      }
      state.rooms = [...[room], ...state.rooms];
    }

    const changeLoadingAction = (value) => {
      state.loadingAction = value;
    }

    // Function submit api room
    async function submitRooms(rooms) {
      if (props.updateWithEndpoint) {
        emit('handleOnlyValidateRoom', {validate: !v$.value.$invalid, type: props.roomSubType});
        emit('onUpdateWidthEndpoint', rooms, props.roomSubType)
        return;
      }
      state.loadingAction = true;
      state.rooms.map(e => {
        e.exception = false
        return e
      })
      await Promise.all(rooms?.map(async e => {
        e['capacity'] = (e['capacity'] == null || e['capacity'] === '' || !isNumber(+e['capacity']) || e['capacity'] === '-') ? null : +e['capacity'];
        if (e.id && e['capacity'] == null) {
          const room = await roomService.getRoom(e.id)
          if (room.user && room.statusSensor === SENSOR_STATUS.NORMAL_STARTUP) {
            const roomOnState = state.rooms.find(k => k.id === e.id)
            if (roomOnState) {
              room.exception = true
            }
          }
        }
        delete e.statusSensor
        return e
      }))
      console.log(rooms)
      if (state.rooms.find(k => k.exception === true)) {
        state.loadingAction = false;
        return
      }

      try {
        if (props.isMyFacility) {
          await roomService.createUpdateRooms(rooms);
        } else {
          await roomService.createUpdateRoomsByFacilityID(rooms, props.facilityId);
        }

        state.loadingAction = false;
        Swal.fire({
          title: "",
          text: i18n.global.t('msg.saved'),
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        state.params.page = 1;
        emit('stateUpdateRoom', props.stateUpdateRoom + 1)
        onLoadData();
        return;
      } catch (error) {
        if (error?.response?.data?.message == 'exceptionStatusSensor') {
          state.loadingAction = false;
          error?.response?.data?.exceptionStatusSensor.map(e => {
            const room = state.rooms.find(k => k.id === e)
            if (room) {
              room.exception = true
            }
          })
          return;
        } else {
          state.loadingAction = false;
          duplicatedNames.value = error?.response?.data?.duplicatedNames || [];
          invalid.value = true;
          if (!duplicatedNames.value || duplicatedNames.value.length === 0) {
            let message = error?.response?.data?.message || '';
            Swal.fire({
              title: "",
              text: message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });

            return;
          }
        }

      }
    }

    // Function submit data
    const onSubmit = async () => {
      v$.value.$touch();
      invalid.value = v$.value.$invalid;
      emit('handleOnlyValidateRoom', {validate: !v$.value.$invalid, type: props.roomSubType});
      if (!v$.value.$invalid) {
        let roomsClone = cloneDeep(state.rooms);
        let rooms = roomsClone.map(r => {
          delete r.facility;
          delete r.updatedAt;
          delete r.createdAt;
          delete r.isRegister;
          delete r.isEditable;
          delete r.user;
          return {
            ...r
          }
        });
        if (rooms && rooms.length) {
          if (props.isCreate) {
            emit('createRoom', {payload: rooms, validate: true, type: props.roomSubType});
            return;
          }
          await submitRooms(rooms);
        }
      }
      emit('createRoom', {payload: [], validate: !v$.value.$invalid, type: props.roomSubType});
    }

    // Function onClick delete room
    const onClickDeleteRoom = (room, index) => {
      let message = '';
      if (props.roomSubType === 'SAUNA_DRIED') {
        message = i18n.global.t('t-sauna-room-information');
      } else if (props.roomSubType === 'SAUNA_WET') {
        message = i18n.global.t('t-water-bath-information');
      } else {
        message = i18n.global.t('t-break-information');
      }
      if (room.id) {
        Swal.fire({
          text: i18n.global.t('deleted_message', {message: message}),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: i18n.global.t('yes'),
          cancelButtonText: i18n.global.t('cancel'),
        }).then(async (result) => {
          if (result.value) {
            try {
              let roomDeleteRes
              //handle for facility application
              if (props.isFacilityApplication) {
                roomDeleteRes = await roomService.deleteRoom(room.id, props.facilityId);
              } else {
                roomDeleteRes = await roomService.deleteRoom(room.id);
              }

              if (roomDeleteRes) {
                state.params.page = 1;
                emit('stateUpdateRoom', props.stateUpdateRoom + 1)
                onLoadData();
              }
            } catch (error) {
              await Swal.fire({
                title: "",
                text: error.message,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        });
      } else {
        state.rooms.splice(index, 1);
      }

    }

    // Onload user tablets
    const getUserTablets = async () => {
      try {
        let res = await userService.getUserTablets(props.isMyFacility ? (currentUser?.value?.facilityId || '') : props.facilityId);
        if (res) {
          userTablets.value = res;
        }
      } catch (error) {
        console.log(error);
      }
    }

    //Chane page pagination
    const onChangePage = (pageNumber) => {
      state.params.page = pageNumber;
      getRooms();
    }

    const onLoadData = () => {
      getRooms();
      if (props.roomSubType === 'SAUNA_DRIED') {
        getUserTablets();
      }
    }

    // On load rooms when components created
    onMounted(() => {
      if (!props.isCreate) {
        onLoadData();
      }
    });

    const handleCompareObject = () => {
      const originRooms = get(props.originFacility, 'rooms', []);
      state.rooms.forEach(item => {
        const findRoom = originRooms.findIndex(itemOrigin => itemOrigin.id === item.id)
        const tempRoom = {
          id: item.id,
          isNew: false
        }
        if (findRoom < 0) {
          tempRoom["isNew"] = true
          state.compareRooms.push(tempRoom);
        } else {
          Object.keys(item).forEach(keyRoom => {
            if (keyRoom.toString() !== 'id') {
              tempRoom[keyRoom] = item[keyRoom] != originRooms[findRoom][keyRoom];
            }
          })

          state.compareRooms.push(tempRoom);
        }
      })
    }

    const changeRoom = (index) => {
      state.rooms[index].startTime = formatTime(state.rooms[index].startTime)
    }

    const changeCapacity = async (index) => {
      try {
        if (state.rooms[index].capacity == '' || state.rooms[index].capacity == '-' || state.rooms[index].capacity == null || !isNumber(+state.rooms[index].capacity)) {
          state.rooms[index].capacity = '-'
          const room = await roomService.getRoom(state.rooms[index].id)
          if (room.user && room.statusSensor === SENSOR_STATUS.NORMAL_STARTUP) {
            state.rooms[index].exception = true
            state.stateButton = true
          }
        } else {
          state.rooms[index].capacity = parseInt(state.rooms[index].capacity).toString()
          state.rooms[index].exception = false
          state.stateButton = false
        }

      } catch (e) {
        console.log(e)
      } finally {

      }
    }

    const onKeydownAvgTemperature = (e) => {
      const regex = /[a-zA-Z]+/;
      const regex_special = /[!@#$%^&*()_+\-=[\]{};':"\\|<>/?]+/;
      const checkViet = /[\u00C0-\u1EF9]/g;

      if ((regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') || regex_special.test(e.key) || checkViet.test(e.key) || e.key === '`' || e.key === ' ' || e.key === '~') {
        e.preventDefault();
      }
    }

    const getClassHighlight = (room, keyRoom) => {
      const roomFind = state.compareRooms.find(item => item.id === room.id && !item.isNew);
      if (roomFind) {
        return props.compareObject && roomFind[keyRoom];
      } else {
        return false
      }
    }

    const getClassHighlightTime = (index, key) => {
      const idRoom = state.rooms[index].id || '';

      const item = state.compareRooms.find(itemCompare => itemCompare.id == idRoom);
      if (props.compareObject && item) {
        return item[key];
      } else {
        return false
      }
    }

    return {
      onKeydownAvgTemperature,
      changeCapacity,
      changeRoom,
      roomTitle,
      columns,
      state,
      pagination,
      v$,
      invalid,
      disabled,
      userTablets,
      duplicatedNames,
      onChangePage,
      onAddNewRoom,
      onSubmit,
      onClickDeleteRoom,
      onLoadData,
      changeLoadingAction,
      getI18n,
      i18nMapLabel,
      getClassHighlight,
      getClassHighlightTime
    };

  },
  watch: {
    'facilityId': function () {
      this.onLoadData()
    },
  }
}
</script>

<style lang="scss">
.room-sauna-dried {
  .room-container {
    max-height: 500px;
    overflow: auto;

    .select-room-type {
      width: 200px;
    }

    .room-name {
      width: 180px;
    }

    input {
      min-width: 100px;
    }

    .room-w-100, .form-icon {
      width: 100px;
    }

    table {
      td {
        vertical-align: top;
      }
    }
  }
}

.line-header {
  border-bottom: 1px solid var(--vz-border-color) !important;
  padding-top: 10px;
  padding-bottom: 20px;
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}

.input-error {
  border-color: #ed5e5e;
  padding-right: calc(1.5em + 1rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.highlight-new {
  border-top: 1px double #edc86f !important;
  border-bottom: 1px double #edc86f !important;
}

.highlight {
  border-color: #edc86f;

  .input-group-text {
    border-color: #edc86f;
  }

  .show-highlight {
    border-color: #edc86f;
  }
}


.is-invalid-box-custom {
  border-color: #ed5e5e;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 12 12' width= '12' height= '12' fill= 'none' stroke= '%23ed5e5e' %3e%3ccircle cx= '6' cy= '6' r= '4.5' /%3e%3cpath stroke-linejoin= 'round' d= 'M5.8 3.6h.4L6 6.5z' /%3e%3ccircle cx= '6' cy= '8.2' r= '.6' fill= '%23ed5e5e' stroke= 'none' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
  white-space: initial !important;
}
</style>
